export default {
  header: {
    selectNetwork: 'Select network',
  },
  home: {
    pageTitle: 'This is not a rug pull',
    projectDescription:
      'This is a project that uses non-fungible tokens (NFTs) to represent and bestow value to intangible ideas such as emotions, memories, and creative acts in a way that can be bought, sold, and exchanged. Built on the XRP Ledger (XRPL) with other blockchains forthcoming, this tool gives the means of production and distribution of conceptual art to everyone.',
    requirements: 'You will need:',
    xrplFaucet: 'XRPL credentials',
    nftStorage: 'nft.storage API key',
    xrplFaucetUrl: 'https://xrpl.org/xrp-testnet-faucet.html',
    nftStorageUrl: 'https://nft.storage/',
    button: 'Start',
  },
  error: {
    generic: 'Something went wrong',
    fallback: 'OOPS!',
  },
  draw: {
    pageTitle: 'Step 1: Create',
    instructions: 'Enter your desired text one line at a time. You will see a preview of your final NFT on the right.',
    textInput: 'Enter desired text',
    drawButton: 'Draw',
    resetButton: 'Reset',
    navigateButton: 'Go to next step',
  },
  metadata: {
    pageTitle: 'Step 2: Enter desired metadata',
    instructions: "This data will be stored on nft.storage, so you'll need to enter your own nft.storage api key.",
    editName: 'Edit name',
    editDescription: 'Edit description',
    addCollection: 'Add collection name (optional)',
    addApiKey: 'Add your nft.storage api key',
    revealKey: 'Reveal API key',
    hideKey: 'Hide API key',
    uploadMetadata: 'Upload metadata',
    navigateButton: 'Go to next step',
  },
  mint: {
    pageTitle: 'Step 3: Mint!',
    instructions: 'Connect a Xumm wallet or enter an XRP Wallet seed to mint from your wallet.',
    instructions2: "Review your NFT's other attributes. We've defaulted to the standard ones.",
    walletSeed: 'Wallet seed',
    walletSeedInfo: 'Add your wallet family seed',
    walletAddress: 'Wallet Address',
    flags: 'Flags',
    flagsInfo: '1 Burnable by issuer, 2 Only XRP, 8 Transferable',
    transferFee: 'Transfer fee',
    transferFeeInfo: 'Units of 1/100,000 up to 50000',
    taxon: 'Taxon',
    taxonInfo: 'Arbitrary number to identify a collection of related NFTs',
    txId: 'NFTokenMint Transaction ID',
    txDate: 'NFTokenMint Transaction Date',
    nftId: 'NFTokenID',
    success: 'Success!',
    successView: 'View your transaction',
    xummSuccess: 'You minted successfully on Xumm!',
    xummReject: 'The sign request was rejected.',
    xummError: 'An error has occurred.',
    newMint: 'Make another NFT!',
    metadataInfo: 'The following metadata has been successfully uploaded to NFTStorage:',
  },
  xumm: {
    connect: 'Connect Xumm',
    disconnect: 'Disconnect',
  },
}
